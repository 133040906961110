import React from "react";
import { useRef, useEffect } from "react";
import { useInView } from "framer-motion";
import staticbg from "../../assets/images/Product/bgslider2.webp";
import video from "../../assets/video/slider1.mp4";


import { motion } from "framer-motion";
const Slider = () => {
  const animateVideo = {
    initialVideo: { opacity: "0%" },
    initialbg: { backgroundColor: "#000000" },

    video: {
      opacity: "100%",
      transition: { duration: 0.75, delay: 3.4, ease: [0.33, 1, 0.68, 1] },
    },
    bgcolor: {
      backgroundColor: "#1a1a1a",
      transition: { duration: 2 },
    },
  };

  useEffect(() => {
    let vid = document.getElementById("myVideo");
    vid.volume = 0.058;
    vid.play();
  },[]);

  
 

  return (
    <motion.div
      className='w-full h-screen  top-0 z-30'
      variants={animateVideo}
      // initial='initialbg'
      // animate='bgcolor'
    >
      <motion.img
        variants={animateVideo}
        initial='initialVideo'
        animate='video'
        className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full h-full object-cover z-20'
        src={staticbg}
        alt=''
      />
      <motion.video
        id="myVideo"
        variants={animateVideo}
        initial='initialVideo'
        animate='video'
        className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full h-full object-cover z-30 brightness-50'
        src={video}
        autoPlay
        loop
        muted
        ></motion.video>
       
    </motion.div>
  );
};

export default Slider;
