import Product1 from "../../../assets/images/Product/LIKE NEW PNG MOCKUP DEPAN.webp";
import product2 from "../../../assets/images/Product/LIKE NEW PNG MOCKUP BELAKANG.webp";

const SlideTwo = () => {
  return (
    <div className='w-full bg-slider2 h-[100vh] md:h-[80vh] flex md:flex-row flex-col justify-center bg-cover items-center px-5 py-14 gap-12 sm:px-[5vw]'>
      {/* <img className='h-[80vh] w-full object-cover gradient-opacity-image' src={BgSlider1} alt='' /> */}
      <div className='md:w-[60%] w-full h-[30%] sm:h-full flex flex-col justify-start sm:justify-center items-center sm:items-start gap-4  sm:gap-6'>
        <h1 className='futura-bold text-center sm:text-start text-4xl sm:text-8xl text-white title-slider1-gradient'>
          LIKE NEW <br /> RESTORER
        </h1>
        <h3 className='futura-medium text-xs sm:text-2xl text-white text-center sm:text-start sm:w-[80%] leading-5'>
          Mengkilapkan dan melindungi body motor sehingga motor terlihat seperti{" "}
          <span className='font-extrabold'>baru</span> dan aman bagi cat.
        </h3>
        <div className='flex flex-row gap-2 sm:gap-5'>
          <p className='bg-orange text-white py-1 px-2 rounded-md text-xs sm:text-xl futura-bold italic'>
            MENGKILAP
          </p>
          <p
            className='bg-orange text-white py-1 px-2 rounded-md text-xs
              sm:text-xl futura-bold italic'>
            MELINDUNGI
          </p>
          <p className='bg-orange text-white py-1 px-2 rounded-md text-xs sm:text-xl futura-bold italic'>
            AMAN TERHADAP CAT
          </p>
        </div>
      </div>
      <div className='md:w-[40%] w-full h-[70%] sm:h-full pb-5 sm:pb-0'>
        <div className='flex flex-row gap-5 justify-center items-center h-full'>
          <img src={Product1} className='drop-shadow-lg h-full' alt='' />
          <img src={product2} className='drop-shadow-lg h-full' alt='' />
        </div>
      </div>
    </div>
  );
};

export default SlideTwo;
