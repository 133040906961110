import axios from "axios";
import React from "react";
import { motion } from "framer-motion";

const baseURL = `https://graph.instagram.com/me/media?fields=id,caption,media_url,timestamp,media_type,permalink&access_token=${process.env.REACT_APP_TOKEN}`;

export default function App() {
  const [post, setPost] = React.useState(null);

  React.useEffect(() => {
    axios
      .get(baseURL)
      .then((response) => {
        // console.log(response);
        setPost(response.data.data);
      })
      .catch((err) => {
        // console.error("Error fetching data:", err);
      });
  }, []);

  if (!post) return null;
  var indicator = 0;
  return (
    <div className='w-full h-full grid-row-2  sm:grid-row-1 grid-cols-2 sm:grid-cols-3 grid-flow-row  grid gap-[4vw] sm:gap-[3vw] px-[9vw] sm:px-[14vw] sm:py-[6vw] py-12 instagram-background-gradient bg-[#1a1a1a] content-center align-middle row-end-2'>
      {post &&
        Object.keys(post)
          .slice(0, 25)
          .map((key, index) => {
            const feed = post[key];
            if (
              !(indicator >= 6) &&
              (feed.media_type === "IMAGE" ||
                feed.media_type === "CAROUSEL_ALBUM")
            ) {
              indicator++;
              return (
                <motion.a
                  whileHover={{
                    translateY: -5,
                    filter: "brightness(1.3)",
                  }}
                  whileTap={{
                    translateY: 0,
                  }}
                  initial={{
                    filter: "brightness(1.1)",
                  }}
                  href='https://www.instagram.com/createoto.official/'
                  key={feed.id}>
                  <div
                    className='h-auto aspect-square '
                    style={{
                      width: "100%",
                      height: "100%",
                      position: "relative",
                    }}>
                    <img
                      src={feed.media_url}
                      alt={feed.caption}
                      className='w-full h-full rounded-md sm:rounded-xl object-cover aspect-square object-center hover:w-full brightness-[.90] contrast-[1.1]'
                    />
                    {/* <p>{feed.caption}</p> */}
                  </div>
                </motion.a>
              );
            } else {
              return;
            }
          })}
    </div>
  );
}
