import "./styles/fonts.css";
import "./styles/globals.css";
import "./styles/marketplace.css";
import "./styles/products.css";
import "./styles/menu/menu.css";
import "./styles/about.css";

import Navbar from "./Component/Navbar/Navbar";
import Hero from "./Component/Hero/Hero";
import About from "./Component/About/About";
import Products from "./Component/Products/Products";
import Instagram from "./Component/Instagram/Instagram";
import Contact from "./Component/Contact/Contact";
import Marketplace from "./Component/Marketplace/MarketPlace";
import AudioButton from "./Component/AudioButton/AudioButton";
import { Suspense } from "react";

function App() {
  return (
    <main className='w-full h-full bg-[#1a1a1a]'>
      <Suspense >
        <Navbar />
        <Hero />
        <About />
        <Products />
        <Marketplace />
        <Instagram />
        <Contact />
      </Suspense>
    </main>
  );
}

export default App;
