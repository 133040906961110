"use client"

import Title from './Title'

const about = () => {
  return (
    <div id='about' className=' w-full sm:h-[30vh] my-0  relative  about-product-bg'>
      <Title />
    </div>
  )
}

export default about