import Title from "./Title";
import Slider from "./Slider";
import { useRef, useEffect } from "react";
import { motion, useInView } from "framer-motion";
import { useTransform, useScroll, useMotionValueEvent } from "framer-motion";
import Logo from "../../assets/images/logo/createoto logo.webp";
import staticbg from "../../assets/images/Product/bgslider2.webp";

const Hero = () => {
  const targetRef = useRef(null);

  return (
    <div
      id='hero'
      ref={targetRef}
      className='w-full h-screen relative overflow-hidden'>
      <Title parentRef={targetRef} />
      <Slider />
      <img
        className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[30vw] z-10'
        src={Logo}
        alt=''
      />
    </div>
  );
};

export default Hero;
