import Title from "./Title"
import Content from './Content'
import BgPattern from "../../assets/images/marketplace/bg-pattern.jpg";

const MarketPlace = () => {
  return (
    <div id="marketplace" className='relative w-full h-[70vh] py-10 sm:h-screen lg:h-[100vh] marketplace-background-gradient flex flex-col justify-center items-center lg:py-20 sm:gap-10'>
      <div className="z-30 w-full h-full flex flex-col justify-center items-center sm:gap-10">
          <Title />
          <Content />
      </div>
      
        <img
        className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full h-screen object-cover -z-10'
        src={BgPattern}
        alt=''
      />
    </div>
  )
}

export default MarketPlace