import React from 'react'

const Title = () => {
  return (
    <div className='text-white w-full flex flex-col justify-center items-center pointer-events-none sm:gap-7 lg:gap-0'>
        <div>
            <h1 className='futura-heavy inline-block text-center w-auto h-full text-[5vh] sm:text-[6vw] truncate title-product-gradient'>
                MARKETPLACE
            </h1>
        </div>
        <div>
            
            <h5 className='futura-light text-xs sm:text-[3vh] '>
                VISIT OUR <span className='font-bold'>STORES</span>
            </h5>
        </div>
    </div>
  )
}

export default Title