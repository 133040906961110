import GetData from './getData'
import Title from "./Title"

const Instagram = () => {
  return (
    <div id="instagram" className="w-full h-full flex flex-col instagram-background-gradient sm:pt-20">
        <Title />
        <GetData />
    </div>
  )
}

export default Instagram