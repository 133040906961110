import TokpedLogo from "../../assets/images/marketplace/tokopediaLogo.webp";
import ShopeeLogo from "../../assets/images/marketplace/shopeeLogo.webp";
import LazadaLogo from "../../assets/images/marketplace/lazadaLogo.webp";
import Triangle from "../../assets/images/marketplace/triangle.png";
import Product1 from "../../assets/images/Product/BLACK SHINE PNG MOCKUP DEPAN.webp";
import Product2 from "../../assets/images/Product/LIKE NEW PNG MOCKUP DEPAN.webp";
import Product3 from "../../assets/images/Product/CARB CLEANER DEPAN.webp";

import { motion } from "framer-motion";

const Content = () => {
  return (
    <div className='flex justify-center items-center  w-[90vw] sm:w-[60vw] h-[40vh] sm:h-[60vh] lg:h-screen mt-5 mx-5 gap-3 sm:gap-10'>
      <motion.a
        whileHover={{
          translateY: -5,
          boxShadow: "0 0 30px rgba(255, 255, 255, 0.5)",
          filter: "brightness(1.3)",
          rotate: "-2deg",
        }}
        whileTap={{
          translateY: 0,
          boxShadow: "0 0 0px rgba(255, 255, 255, 0)",
          rotate: "0deg",
        }}
        initial={{
          boxShadow: "0 0 0px rgba(255, 255, 255, 0)",
          filter: "brightness(1.1)",
        }}
        target='_blank'
        href='https://www.tokopedia.com/createoto-3'
        className='w-[33%] h-full border-2 border-green-500  relative overflow-hidden tokped-card-gradient hover:border-green-500'>
        <div className='w-1 h-1 sm:h-2 sm:w-2 absolute z-40 start-0 bg-green-500'></div>
        <div className='w-1 h-1 sm:h-2 sm:w-2 absolute z-40 end-0 bg-green-500'></div>
        <div className='w-1 h-1 sm:h-2 sm:w-2 absolute z-40 start-0 bottom-0 bg-green-500'></div>
        <div className='w-1 h-1 sm:h-2 sm:w-2 absolute z-40 end-0 bottom-0 bg-green-500'></div>
        <h1 className='absolute start-0 bottom-0 ps-1 z-10 text-white sm:text-[200%] w-full futura-bold italic tokped-text-gradient '>
          TOKOPEDIA
        </h1>
        <img
          src={TokpedLogo}
          className='w-10 h-10 sm:w-16 sm:h-16 absolute z-10 end-0 top-0 m-1'
          alt=''
        />
        <img
          src={Triangle}
          className='w-10 h-10 sm:h-24 sm:w-24 absolute z-10 start-0 opacity-50'
          alt=''
        />
        <img
          src={Product1}
          className='drop-shadow-md origin-top h-[120%] rotate-product absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2
 brightness-90 hover:brightness-100 '
          alt=''
        />
      </motion.a>
      <motion.a
        whileHover={{
          translateY: -5,
          boxShadow: "0 0 30px rgba(255, 255, 255, 0.5)",
          filter: "brightness(1.3)",
          rotate: "-2deg",
        }}
        whileTap={{
          translateY: 0,
          boxShadow: "0 0 0px rgba(255, 255, 255, 0)",
          rotate: "0deg",
        }}
        initial={{
          boxShadow: "0 0 0px rgba(255, 255, 255, 0)",
          filter: "brightness(1.1)",
        }}
        target='_blank'
        href='https://shopee.co.id/shop/1081838564'
        className='w-[33%] h-full border-2 border-orange relative overflow-hidden shopee-card-gradient hover:border-orange'>
        <div className='w-1 h-1 sm:h-2 sm:w-2 absolute z-40 start-0 bg-orange'></div>
        <div className='w-1 h-1 sm:h-2 sm:w-2 absolute z-40 end-0 bg-orange'></div>
        <div className='w-1 h-1 sm:h-2 sm:w-2 absolute z-40 start-0 bottom-0 bg-orange'></div>
        <div className='w-1 h-1 sm:h-2 sm:w-2 absolute z-40 end-0 bottom-0 bg-orange'></div>
        <h1 className='absolute start-0 bottom-0 ps-1 z-10 text-white sm:text-[200%] w-full futura-bold italic shopee-text-gradient '>
          SHOPEE
        </h1>
        <img
          src={ShopeeLogo}
          className='w-10 h-10 sm:w-16 sm:h-16 absolute z-10 end-0 top-0 m-1'
          alt=''
        />
        <img
          src={Triangle}
          className='w-10 h-10 sm:h-24 sm:w-24 absolute z-10 start-0 opacity-50'
          alt=''
        />
        <img
          src={Product2}
          className='drop-shadow-md origin-top h-[120%] rotate-product absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2
 brightness-90 hover:brightness-100 '
          alt=''
        />
      </motion.a>
      <motion.a
        whileHover={{
          translateY: -5,
          boxShadow: "0 0 30px rgba(255, 255, 255, 0.5)",
          filter: "brightness(1.3)",
          rotate: "-2deg",
        }}
        whileTap={{
          translateY: 0,
          boxShadow: "0 0 0px rgba(255, 255, 255, 0)",
          rotate: "0deg",
        }}
        initial={{
          boxShadow: "0 0 0px rgba(255, 255, 255, 0)",
          filter: "brightness(1.1)",
        }}
        target='_blank'
        href='https://www.lazada.co.id/shop/createoto/?spm=a2o4j.pdp_revamp.seller.1.3320631dmAkCu9&itemId=7861074560&channelSource=pdp'
        className='w-[33%] h-full border-2 border-[#fa0fc7] relative overflow-hidden lazada-card-gradient hover:border-[#fa0fc7]'>
        <div className='w-1 h-1 sm:h-2 sm:w-2 absolute z-40 start-0 bg-[#fa0fc7]'></div>
        <div className='w-1 h-1 sm:h-2 sm:w-2 absolute z-40 end-0 bg-[#fa0fc7]'></div>
        <div className='w-1 h-1 sm:h-2 sm:w-2 absolute z-40 start-0 bottom-0 bg-[#fa0fc7]'></div>
        <div className='w-1 h-1 sm:h-2 sm:w-2 absolute z-40 end-0 bottom-0 bg-[#fa0fc7]'></div>
        <h1 className='absolute start-0 bottom-0 ps-1 z-10 text-white sm:text-[200%] w-full futura-bold italic lazada-text-gradient '>
          LAZADA
        </h1>
        <img
          src={LazadaLogo}
          className='w-10 h-10 sm:w-16 sm:h-16 absolute z-10 end-0 top-0 m-1'
          alt=''
        />
        <img
          src={Triangle}
          className='w-10 h-10 sm:h-24 sm:w-24 absolute z-10 start-0 opacity-50'
          alt=''
        />
        <img
          src={Product3}
          className='drop-shadow-md origin-top h-[120%] rotate-product absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2
 brightness-90 hover:brightness-100 '
          alt=''
        />
      </motion.a>
    </div>
    // <div className='w-full h-full mt-10'>
    //     <div className='w-full h-full py-10 sm:py-20 xl:py-0 xl:-my-[20vh] px-[9vw] sm:px-[14vw] flex flex-col sm:flex-row gap-5 sm:gap-10 sm:items-center sm:justify-center'>
    //         <a href='https://www.tokopedia.com/createoto-3' id='tokopedia' className='card-bg rounded flex flex-row sm:flex-col items-center justify-center h-28 sm:h-auto w-full sm:w-1/3 sm:min-w-72 sm:max-w-72  p-5 sm:p-10 gap-5 sm:gap-8  border-green-400'>
    //             <div className='w-2/3 sm:-my-20 sm:w-full'>
    //                 <img className='pt-5' src={TokpedText} alt="" />
    //             </div>
    //             <div className='w-1/3 sm:w-full flex justify-center'>
    //                 <img className='max-h-20 sm:max-h-40' src={TokpedLogo} alt="" />
    //             </div>
    //             <div className='hidden sm:flex'>
    //                 <button type="button" className="text-white bg-gradient-to-r from-green-400 via-purple-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-purple-500 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-10  py-2.5 text-center ">Our Tokopedia</button>

    //             </div>
    //         </a>
    //         <a href='https://shopee.co.id/shop/1081838564' id='shopee' className='card-bg rounded flex flex-row sm:flex-col items-center justify-center h-28 sm:h-auto w-full sm:w-1/3 sm:min-w-72 sm:max-w-72  p-5 sm:p-10 gap-5 sm:gap-8  border-orange'>
    //             <div className='w-2/3 sm:-my-20 sm:w-full'>
    //                 <img className='pt-5' src={ShopeeText} alt="" />
    //             </div>
    //             <div className='w-1/3 sm:w-full flex justify-center'>
    //                 <img className='max-h-20 sm:max-h-40' src={ShopeeLogo} alt="" />
    //             </div>
    //             <div className='hidden sm:flex'>

    //                 <button type="button" className="text-white bg-gradient-to-r from-red-400 via-red-500 to-orange hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-500 dark:focus:ring-red-800 font-medium rounded-lg text-sm px-10 py-2.5 text-center ">Our Shopee</button>

    //             </div>
    //         </a>
    //         <a href='https://www.lazada.co.id/shop/createoto/?spm=a2o4j.pdp_revamp.seller.1.3320631dmAkCu9&itemId=7861074560&channelSource=pdp' id='lazada' className='card-bg rounded flex flex-row sm:flex-col items-center justify-center h-28 sm:h-auto w-full sm:w-1/3 sm:min-w-72 sm:max-w-72  p-5 sm:p-10 gap-5 sm:gap-8  border-pink'>
    //             <div className='w-2/3 sm:-my-20 sm:w-full'>
    //                 <img className='pt-2 sm:pt-5' src={LazadaText} alt="" />
    //             </div>
    //             <div className='w-1/3 sm:w-full flex justify-center'>
    //                 <img className='max-h-20 sm:max-h-40' src={LazadaLogo} alt="" />
    //             </div>
    //             <div className='hidden sm:flex'>
    //                 <button type="button" className="text-white bg-gradient-to-r from-pink via-pink-500 to-orange hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-pink-500 dark:focus:ring-pink-800 font-medium rounded-lg py-2.5 text-center text-sm px-10">Our Lazada</button>

    //             </div>

    //         </a>
    //         {/* <a href='https://shopee.co.id/shop/1081838564' id='shopee' className='bg-white rounded flex flex-row items-center justify-center h-28 w-full p-5 gap-5  border-orange'>
    //             <div className='w-2/3 '>
    //                 <img className=' pt-7' src={ShopeeText} alt="" />
    //             </div>
    //             <div className='w-1/3 '>
    //                 <img className='' src={ShopeeLogo} alt="" />

    //             </div>
    //         </a>
    //         <a href='https://www.lazada.co.id/shop/createoto/?spm=a2o4j.pdp_revamp.seller.1.3320631dmAkCu9&itemId=7861074560&channelSource=pdp' id='lazada' className='bg-white rounded flex flex-row items-center justify-center h-28 w-full p-5 gap-5  border-pink'>
    //             <div className='w-2/3'>
    //                 <img className=' pt-1' src={LazadaText} alt="" />
    //             </div>
    //             <div className='w-1/3'>
    //                 <img className='' src={LazadaLogo} alt="" />

    //             </div>
    //         </a> */}
    //     </div>
    // </div>
  );
};

export default Content;
