import BgSlider1 from "../../../assets/images/Product/bgslider1.webp";
import Product1 from "../../../assets/images/Product/BLACK SHINE PNG MOCKUP DEPAN.webp";
import product2 from "../../../assets/images/Product/BLACK SHINE PNG MOCKUP BELAKANG.webp";


 
const SlideOne = () => {
  return (
    <div className='w-full bg-slider1 h-[100vh] md:h-[80vh] flex md:flex-row flex-col justify-center bg-cover items-center px-5 py-14 gap-12 sm:px-[5vw]'>
        {/* <img className='h-[80vh] w-full object-cover gradient-opacity-image' src={BgSlider1} alt='' /> */}
        <div className="md:w-[60%] w-full h-[30%] sm:h-full flex flex-col justify-start sm:justify-center items-center sm:items-start gap-4  sm:gap-6">
          <h1 className="futura-bold text-center sm:text-start text-4xl sm:text-8xl text-white title-slider1-gradient">
            BLACK SHINE <br /> TIRE FOAM
          </h1>
          <h3 className="futura-medium text-xs sm:text-2xl text-white text-center sm:text-start sm:w-[80%] leading-5">
            dengan formulasi <span className="font-extrabold">ACTIVE FOAM</span> dapat membersihkan, melindung, dan mengembalikan warna hitam pada ban mobil sehingga terlihat baru
          </h3>
          <div className="flex flex-row gap-2 sm:gap-5">
              <p className="bg-orange text-white py-1 px-2 rounded-md text-xs sm:text-xl futura-bold italic">WET LOOKS</p>
              <p className="bg-orange text-white py-1 px-2 rounded-md text-xs
              sm:text-xl futura-bold italic">TAHAN AIR</p>
              <p className="bg-orange text-white py-1 px-2 rounded-md text-xs sm:text-xl futura-bold italic">TAHAN LAMA</p>
          </div>
        </div>
        <div className="md:w-[40%] w-full h-[70%] sm:h-full pb-5 sm:pb-0">
          <div className="flex flex-row gap-5 justify-center items-center h-full">
            <img src={Product1} className="drop-shadow-lg h-full" alt="" />
            <img src={product2} className="drop-shadow-lg h-full" alt="" />
          
          </div>
        </div>
    </div>
  )
}

export default SlideOne