import Product1 from "../../../assets/images/Product/CARB CLEANER DEPAN.webp";
import product2 from "../../../assets/images/Product/CARB CLEANER BELAKANG.webp";
import Genetic from "../../../assets/images/Product/genetic.svg";

const SlideThree = () => {
  return (
    <div className='w-full bg-slider3 h-[100vh] md:h-[80vh] flex md:flex-row flex-col justify-center bg-cover items-center px-5 py-14 gap-12 sm:px-[5vw]'>
      {/* <img className='h-[80vh] w-full object-cover gradient-opacity-image' src={BgSlider1} alt='' /> */}
      <div className='md:w-[60%] w-full h-[30%] sm:h-full flex flex-col justify-start sm:justify-center items-center sm:items-start gap-4  sm:gap-6'>
        <h1 className='futura-bold text-center sm:text-start text-4xl sm:text-8xl text-white title-slider4-gradient drop-shadow-[0_10.0px_5.0px_#5b3d87]'>
          CARB CLEANER
        </h1>
        <h3 className='futura-medium text-xs sm:text-2xl text-white text-center sm:text-start sm:w-[80%] leading-5'>
          Dengan formulasi{" "}
          <span className='font-extrabold'>
            UCT (ULTIMATE CLEANSING TECHNOLOGY)
          </span>
          , cepat membersihkan semua kerak dan kotoran yang menumpuk pada
          karburator maupun injector, dan mengurangi emisi gas buang tinggi.
        </h3>
        <div className='flex flex-row gap-2 sm:gap-5'>
          <p className='bg-purple-200  border-2 border-white text-purple-600 py-1 px-2 rounded-md text-xs sm:text-xl futura-bold italic truncate flex justify-center items-center'>
            ULTIMATE CLEANSING TECHNOLOGY{" "}
            <span>
              <img src={Genetic} className='h-full w-auto ps-2' alt='' />
            </span>
          </p>
        </div>
      </div>
      <div className='md:w-[40%] w-full h-[70%] sm:h-full pb-5 sm:pb-0'>
        <div className='flex flex-row gap-5 justify-center items-center h-full'>
          <img src={Product1} className='drop-shadow-lg h-full' alt='' />
          <img src={product2} className='drop-shadow-lg h-full' alt='' />
        </div>
      </div>
    </div>
  );
};

export default SlideThree;
