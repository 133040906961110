import { useRef, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
// import Slider1 from "../../assets/images/Product/slider1.webp";
// import Slider2 from "../../assets/images/Product/slider2.webp";
// import Slider3 from "../../assets/images/Product/slider3.webp";
// import Slider4 from "../../assets/images/Product/slider4.webp";

// Import Swiper styles
import "swiper/css/navigation";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";
import "swiper/css/pagination";

import "swiper/css";
import Slider1 from "./productSlides/SlideOne";
import Slider2 from "./productSlides/SlideTwo";
import Slider3 from "./productSlides/SlideThree";
import Slider4 from "./productSlides/SlideFour";
import { Autoplay, EffectFade, Navigation, Pagination } from "swiper/modules";

const Products = () => {
  useEffect(() => {}, []);

  return (
    <div>
      <div className='text-white w-full flex flex-col justify-center items-center relative futura-heavy futura-heavy gap-2 sm:gap-10  title-product-bg'>
        <Swiper
          className='my-3 swiper-wrapper relative w-full h-ful flex justify-center items-center '
          loop={true}
          modules={[Autoplay]}
          speed={10000}
          autoplay={{
            delay: 0,
            disableOnInteraction: false,
          }}
          breakpoints={{
            320: { slidesPerView: 1, spaceBetween: 40 },

            1024: { slidesPerView: 2, spaceBetween: 0 },
          }}
          slidesPerView={2}
          // spaceBetween={2}
          freeMode={false}>
          <SwiperSlide className=''>
            <h1 className='inline-block text-center w-auto  text-[5vh] sm:text-[4vw] truncate title-product-gradient'>
              FEATURED PRODUCTS
            </h1>
          </SwiperSlide>
          <SwiperSlide className=''>
            <h1 className='inline-block text-center w-auto  text-[5vh] sm:text-[4vw] truncate title-product-gradient'>
              FEATURED PRODUCTS
            </h1>
          </SwiperSlide>
          <SwiperSlide>
            <h1 className='inline-block text-center w-auto  text-[5vh] sm:text-[4vw] truncate title-product-gradient'>
              FEATURED PRODUCTS
            </h1>
          </SwiperSlide>
          <SwiperSlide>
            <h1 className='inline-block text-center w-auto  text-[5vh] sm:text-[4vw] truncate title-product-gradient'>
              FEATURED PRODUCTS
            </h1>
          </SwiperSlide>
          <SwiperSlide>
            <h1 className='inline-block text-center w-auto  text-[5vh] sm:text-[4vw] truncate title-product-gradient'>
              FEATURED PRODUCTS
            </h1>
          </SwiperSlide>
        </Swiper>
      </div>
      <Swiper
        navigation={true}
        pagination={true}

        style={{
          "--swiper-pagination-color": "#DB00BA",
          "--swiper-pagination-bullet-inactive-color": "#7e006b",
          "--swiper-pagination-bullet-inactive-opacity": "1",
          "--swiper-pagination-bullet-size": "10px",
          "--swiper-pagination-bullet-horizontal-gap": "6px",
        }}
        modules={[Pagination, EffectFade, Navigation, Autoplay]}
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
        }}
        speed={600}
        loop={true}
        effect={"fade"}
        className='bg-none  h-auto w-full mx-10 product-slider-wrapper'>
        <SwiperSlide className='w-full h-full'>
          <Slider1 />
        </SwiperSlide>
        <SwiperSlide className='w-full h-full'>
          <Slider2 />
        </SwiperSlide>
        <SwiperSlide className='w-full h-full'>
          <Slider3 />
        </SwiperSlide>
        <SwiperSlide className='w-full h-full'>
          <Slider4 />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default Products;
